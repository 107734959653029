import React, { lazy, Suspense, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

// Atom
import PlayButton from './play-button';

const Plyr = lazy(() => import('plyr-react'));

// Settings
const settings = {
  controls: ['play', 'mute', 'volume', 'fullscreen'],
  fullscreen: { enabled: true, fallback: true, iosNative: true },
  muted: true,
  ratio: '16:9',
};

const Video = ({ title, id, platform, thumbnail, autoplay }) => {
  const isSSR = typeof window === 'undefined';

  const buttonRef = useRef(null);
  const thumbnailRef = useRef(null);

  const img = getImage(thumbnail);

  const classHandler = (remove = false) => {
    if (remove) {
      // removing classes
      buttonRef?.current?.classList.remove(
        'opacity-0',
        'pointer-events-none',
        'touch-none'
      );
      thumbnailRef?.current?.classList.remove(
        'opacity-0',
        'pointer-events-none',
        'touch-none'
      );
    } else {
      buttonRef?.current?.classList.add(
        'opacity-0',
        'pointer-events-none',
        'touch-none'
      );
      thumbnailRef?.current?.classList.add(
        'opacity-0',
        'pointer-events-none',
        'touch-none'
      );
    }
  };

  const videoRef = useCallback((node) => {
    if (node !== null && node?.plyr && node?.plyr?.source !== null) {
      node.plyr.on('ready', () => {
        //
        // mute for auto-play
        node.plyr.muted = true;
        if (!isSSR && !window.localStorage.getItem('autoplayed')) {
          setTimeout(() => {
            node.plyr.play();
          }, 500);
        }
        buttonRef?.current?.removeAttribute('disabled');
        buttonRef?.current?.addEventListener('click', (e) => {
          e.preventDefault();
          node.plyr.muted = false;
          node.plyr.play();
        });
      });

      node.plyr.on('playing', () => {
        // hide thumbnail and button
        classHandler(false);
        if (!isSSR) window.localStorage.setItem('autoplayed', true);
      });

      node.plyr.on('ended', () => {
        // show thumbnail and button
        classHandler(true);
      });
    }
  });

  return (
    <div className="w-full relative aspect-[16/9]">
      {!isSSR && (
        <Suspense fallback={<div className="bg-black aspect-[16/9]" />}>
          <Plyr
            ref={videoRef}
            source={{
              type: 'video',
              title,
              sources: [
                {
                  src: id,
                  provider: platform.toLowerCase() || 'vimeo',
                },
              ],
              poster: false,
            }}
            options={settings}
            autoPlay={autoplay}
          />
        </Suspense>
      )}

      {img && (
        <div
          className="absolute inset-0 transition-opacity duration-1000"
          ref={thumbnailRef}
        >
          <GatsbyImage
            image={img}
            // title={thumbnail.title}
            alt={thumbnail.description}
            className="h-full"
          />
          <div className="absolute inset-0 bg-grey-overlay bg-opacity-30" />
        </div>
      )}

      <PlayButton
        ref={buttonRef}
        disabled
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      />
    </div>
  );
};

Video.defaultProps = {
  title: '',
  platform: 'Vimeo',
  thumbnail: {},
  autoplay: false,
};

Video.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string.isRequired,
  platform: PropTypes.oneOf(['Vimeo', 'Youtube']),
  thumbnail: PropTypes.object,
  autoplay: PropTypes.bool,
};

export default Video;
