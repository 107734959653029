import React, { lazy, Suspense, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { getScrollPosition } from '../components/helpers';

const Plyr = lazy(() => import('plyr-react'));

// Settings
const settings = {
  controls: [],
  fullscreen: { enabled: false },
  storage: { enabled: false },
  autoplay: true,
  autopause: false,
  muted: true,
  clickToPlay: false,
  ratio: '16:9',
};

const Video = ({
  title,
  id,
  platform,
  thumbnail,
  videoOnLoop,
  triggerOnValue,
}) => {
  const isSSR = typeof window === 'undefined';

  const ref = useRef(null);
  const thumbnailRef = useRef(null);

  const img = getImage(thumbnail);

  const classHandler = (remove = false) => {
    if (remove) {
      thumbnailRef?.current?.classList.remove(
        'opacity-0',
        'pointer-events-none',
        'touch-none'
      );
    } else {
      thumbnailRef?.current?.classList.add(
        'opacity-0',
        'pointer-events-none',
        'touch-none'
      );
    }
  };

  const videoRef = useCallback((node) => {
    if (node !== null && node?.plyr && node?.plyr?.source !== null) {
      node.plyr.on('ready', () => {
        node.plyr.muted = true;

        if (triggerOnValue) {
          let prevScroll = 0;

          window.addEventListener('scroll', (e) => {
            if (!ref?.current) return;
            const scroll = getScrollPosition();
            const { height } = ref.current.getBoundingClientRect();

            if (
              scroll - prevScroll > 0 &&
              scroll >
                ref.current.offsetTop + height * 0.8 - window.innerHeight &&
              !node.plyr.playing
            ) {
              node.plyr.play();
            }

            prevScroll = scroll;
          });
        } else {
          setTimeout(() => {
            node.plyr.play();
          }, 500);
        }
      });

      node.plyr.on('playing', () => {
        // hide thumbnail and button
        classHandler(false);
      });
      node.plyr.on('ended', () => {
        // show thumbnail and button
        classHandler(true);
      });

      if (!videoOnLoop) {
        node.plyr.on('timeupdate', () => {
          // hack to stop video before suggestion appears
          if (
            node.plyr.duration &&
            node.plyr.currentTime &&
            node.plyr.duration - node.plyr.currentTime < 0.2
          ) {
            classHandler(true);
            node.plyr.pause();
          }
        });
      }
    }
  });

  return (
    <div className="w-full relative aspect-[16/9]" ref={ref}>
      {!isSSR && (
        <Suspense fallback={<div className="bg-black aspect-[16/9]" />}>
          <Plyr
            ref={videoRef}
            source={{
              type: 'video',
              title,
              sources: [
                {
                  src: id,
                  provider: platform.toLowerCase() || 'vimeo',
                },
              ],
              poster: false,
            }}
            options={{ ...settings, loop: { active: videoOnLoop } }}
          />
        </Suspense>
      )}
      {img && (
        <div
          className="absolute inset-0 transition-opacity duration-1000"
          ref={thumbnailRef}
        >
          <GatsbyImage
            image={img}
            // title={thumbnail.title}
            alt={thumbnail.description}
            className="h-full"
          />
          {/* <div className="absolute inset-0 bg-grey-overlay bg-opacity-30" /> */}
        </div>
      )}
    </div>
  );
};

Video.defaultProps = {
  title: '',
  platform: 'Vimeo',
  thumbnail: {},
  videoOnLoop: false,
  triggerOnValue: false,
};

Video.propTypes = {
  title: PropTypes.string,
  id: PropTypes.string.isRequired,
  platform: PropTypes.oneOf(['Vimeo', 'Youtube']),
  thumbnail: PropTypes.object,
  videoOnLoop: PropTypes.bool,
  triggerOnValue: PropTypes.bool,
};

export default Video;
